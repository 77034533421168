@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');

.text-shadow-lg {
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
}

.text-shadow-sm {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
